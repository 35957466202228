<template>
  <div>
    <v-form
      :id="formId"
      ref="companyInfoForm"
      v-model="isFormValid"
      class="multi-col-validation"
      @submit.prevent="onSubmit"
    >
      <v-row>
        <v-col md="12" cols="12">
          <v-text-field
            id="companyname"
            v-model="companyData.name"
            :rules="[validators.required]"
            label="Company name"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col md="12" cols="12" class="pb-1">
          <v-text-field
            id="companydisplayname"
            v-model="companyData.displayName"
            :rules="[validators.required]"
            label="Company name displayed"
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col md="12" cols="12">
          <v-radio-group
            id="language"
            v-model="companyData.language"
            row
            hide-details
            class="radio-group-language"
            @change="onLanguageChange"
          >
            <template #label>
              <div class="label-custom label-language">
                {{ $t('formLabelLanguagePreference') }}
              </div>
            </template>
            <v-radio :label="$t('formLabelLanguageGermanFormal')" :value="'de_form'"></v-radio>
            <v-radio :label="$t('formLabelLanguageGermanInformal')" :value="'de_coll'"></v-radio>
            <v-radio :label="$t('formLabelLanguageEnglish')" :value="'en'"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import { required, emailValidator, phoneNumberValidator, ageValidator } from '@core/utils/validation';
import { ORG_TYPE_EXISTING, ORG_TYPE_NEW } from '@/constants';
import { createCompany } from '@/api/companies';

export default {
  components: {},
  setup(props, { emit }) {
    const companyInfoForm = ref(null);
    const isFormValid = ref(false);
    const orgType = ref(ORG_TYPE_NEW);

    const onRadioChange = () => {
      orgType.value = props.companyData.orgType;
    };

    const onLanguageChange = (e) => {
      if (e.slice(-5) === '_form') {
        props.companyData.useFormalLang = true;
      } else {
        props.companyData.useFormalLang = false;
      }
    };

    const onSubmit = async () => {
      const isValid = companyInfoForm.value.validate();
      if (!isValid) return;
      const createdId = await createCompany(props.companyData).catch((err) => {});
      emit('companyCreated', createdId);
    };

    return {
      companyInfoForm,
      isFormValid,
      onSubmit,
      ORG_TYPE_NEW,
      ORG_TYPE_EXISTING,
      onRadioChange,
      onLanguageChange,
      orgType,

      validators: {
        required,
        emailValidator,
        phoneNumberValidator,
        ageValidator,
      },
    };
  },
  props: {
    formId: {
      required: false,
    },
    companyData: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.radio-group-language {
  .v-label {
    width: 100%;
  }
  .label-language {
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
}
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
