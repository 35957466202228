<template>
  <div class="py-sm-10 py5">
    <div class="row">
      <div class="mx-auto col-sm-12 col-md-10 col-lg-10 col-12">
        <v-card class="v-card--no-border v-card--no-bg mb-10">
          <h1 class="font-weight-bold mb-5 text-center">
            {{ $t('Companies') }}
          </h1>
        </v-card>
      </div>
    </div>

    <v-row class="justify-center">
      <v-col cols="12" md="10" class="mx-auto">
        <div>
          <div class="pb-5">
            <div class="my-bav__table mb-5">
              <v-card>
                <v-card-text>
                  <h2>{{ $t('myBavSearchNFilter') }}</h2>
                </v-card-text>
                <v-card-text class="d-flex align-center flex-wrap pb-0">
                  <v-row class="px-3">
                    <v-col cols="12" md="4" class="px-0">
                      <v-text-field
                        v-model="searchQuery"
                        single-line
                        dense
                        outlined
                        hide-details
                        clearable
                        :label="$t('formPlaceholderSearch')"
                        :placeholder="$t('formPlaceholderSearch')"
                        class="mb-5 me-3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4" class="px-0">
                      <v-select
                        v-model="statusFilter"
                        :items="statusOptions"
                        :label="$t('adminCompanyFilterStatusLabel')"
                        :placeholder="$t('adminCompanyFilterStatusLabel')"
                        clearable
                        single-line
                        outlined
                        dense
                        hide-details
                        class="mb-5 me-3"
                        @change="setStatus"
                      ></v-select>
                    </v-col>
                    <!-- Filter for integration
                    <v-col cols="12" md="4" class="px-0">
                      <v-select
                        single-line
                        outlined
                        dense
                        v-model="filterByIntegration"
                        @change="setFilterByIntegration"
                        hide-details
                        clearable
                        :items="IntegrationOptions"
                        :label="$t('Filter by integration')"
                        :placeholder="$t('Filter by integration')"
                        class="mb-5 me-3"
                      >
                      </v-select>
                    </v-col> -->
                  </v-row>
                </v-card-text>
                <v-card-text class="d-flex align-center flex-wrap pb-0 border-top justify-end">
                  <span class="py-5">
                    <v-btn color="primary" class="mr-3" @click="createCompanyModal = true">
                      {{ $t('Create company') }}
                    </v-btn>
                  </span>
                </v-card-text>

                <v-data-table
                  :headers="headers"
                  :items="loadedCompanies"
                  :server-items-length="totalCompanies"
                  :loading="loadingCompanies"
                  :footer-props="{
                    'items-per-page-options': [15, 30, 50, 100],
                    'items-per-page-text': $t('itemsPerPage'),
                  }"
                  class="text-no-wrap cursor-pointer"
                  @update:items-per-page="setLimit"
                  @click:row="handleRowClick"
                  @pagination="handlePagination"
                >
                  <template #[`header.trending`]>
                    <v-icon size="22">
                      {{ icons.mdiTrendingUp }}
                    </v-icon>
                  </template>
                  <template #[`item.companyName`]="{ item }">
                    <span class="text-no-wrap th">{{ item.companyName }}</span>
                  </template>
                  <template #[`item.companyId`]="{ item }">
                    <span class="text-no-wrap th">{{ item.id.slice(0, 16) + '...' }}</span>
                  </template>

                  <!-- actions -->
                  <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                      <!-- edit -->
                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" @click="$router.push(`/companies/${item.id}`)" v-on="on">
                            <v-icon size="18">
                              {{ icons.mdiPencilOutline }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('edit') }}</span>
                      </v-tooltip>

                      <!-- delete -->

                      <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                          <v-btn icon small v-bind="attrs" @click="onDelete(item)" v-on="on">
                            <v-icon size="18">
                              {{ icons.mdiDeleteOutline }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('delete') }}</span>
                      </v-tooltip>
                    </div>
                  </template>

                  <template slot="no-data">
                    <div class="my-5">
                      <p>{{ $t('myBavNoResults') }}</p>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
              <!-- delete  -->

              <ModalWindow :is-open="deleteCompanyModal" error>
                <template #content>
                  <p class="text-base">
                    {{
                      $t('deleteCompanyConfirmation', {
                        name: companyNameToDelete,
                      })
                    }}
                  </p>
                </template>
                <template #actions>
                  <v-btn outlined color="primary" @click="deleteCompanyModal = false">
                    {{ $t('buttonCancel') }}
                  </v-btn>
                  <v-btn color="primary" @click="confirmDelete">
                    {{ $t('buttonConfirm') }}
                  </v-btn>
                </template>
              </ModalWindow>
              <!-- continue  -->

              <ModalWindow :is-open="continueCreatingModal" success>
                <template #content>
                  <p class="text-base">
                    The company was created successfully. Would you like to continue to the onboarding setup?
                  </p>
                </template>
                <template #actions>
                  <v-btn outlined color="primary" @click="onSkipOnboarding"> No</v-btn>
                  <v-btn color="primary" @click="$router.push(`/companies/${newCompanyId}`)"> Yes</v-btn>
                </template>
              </ModalWindow>

              <!-- create company -->
              <ModalWindow :is-open="createCompanyModal" :title="$t('headlineCreateCompany')" hasForm>
                <template #content>
                  <add-company-form
                    :companyData="companyData"
                    formId="createCompanyForm"
                    @companyCreated="onCreatedCompany"
                  />
                </template>
                <template #actions>
                  <v-btn outlined color="primary" class="btn--default" @click="createCompanyModal = false">
                    {{ $t('buttonCancel') }}
                  </v-btn>
                  <v-btn color="primary" class="btn--default" type="submit" form="createCompanyForm">
                    {{ $t('buttonAdd') }}
                  </v-btn>
                </template>
              </ModalWindow>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
  mdiDownloadOutline,
  mdiInformationOutline,
  mdiCheckCircle,
  mdiUploadOutline,
} from '@mdi/js';
import { ref, getCurrentInstance, computed, watch } from '@vue/composition-api';
import ModalWindow from '@/components/modal/ModalWindow';
import { getCompaniesList, deactivateCompany } from '../../api/companies';
import AddCompanyForm from '@/components/company-add/AddCompanyForm.vue';
import { CONTRACT_TRANSLATION_MAP, CONTRACT_ONBOARDED, ORG_TYPE_NEW } from '@/constants';

export default {
  name: 'GlobalAdminCompanies',
  components: { ModalWindow, AddCompanyForm },
  setup() {
    const vm = getCurrentInstance().proxy;
    const loadedCompanies = ref([]);
    const totalCompanies = ref(0);
    const loadingCompanies = ref(false);
    const companiesOptions = ref([]);

    const limit = ref(15);
    const offset = ref(0);
    const currentPage = ref(1);
    const searchQuery = ref('');
    const statusFilter = ref('');
    const rerenderKey = ref(0);

    const companyToDelete = ref(null);
    const companyNameToDelete = ref(null);
    const selectedCopmanies = ref([]);
    const deleteCompanyModal = ref(false);
    const createCompanyModal = ref(false);
    const continueCreatingModal = ref(false);

    const newCompanyId = ref(null);

    const headers = computed(() => {
      return [
        {
          text: vm.$t('tableHeaderCompany'),
          value: 'companyName',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('Company ID'),
          value: 'companyId',
          sortable: false,
          class: 'text-uppercase',
        },
        {
          text: vm.$t('tableHeaderActions'),
          value: 'actions',
          align: 'center',
          sortable: false,
          class: 'text-uppercase',
        },
      ];
    });

    const fetchAll = () => {
      loadingCompanies.value = true;
      getCompaniesList(offset.value, limit.value, searchQuery.value).then((res) => {
        loadedCompanies.value = res.data.page;
        totalCompanies.value = res.data.total;
        loadingCompanies.value = false;
      });
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) offset.value += limit.value;
      if (currentPage.value > event.page) offset.value -= limit.value;

      currentPage.value = event.page;
      fetchAll();
    };

    const setLimit = (v) => {
      limit.value = v;
      currentPage.value = 1;
      offset.value = 0;
      fetchAll();
    };

    const handleRowClick = (company) => {
      vm.$router.push(`companies/${company.id}`);
    };

    watch(searchQuery, () => {
      fetchAll();
    });

    fetchAll();
    const statusOptions = ref([
      {
        value: CONTRACT_ONBOARDED,
        text: vm.$t(CONTRACT_TRANSLATION_MAP[CONTRACT_ONBOARDED]),
      },
      {
        value: 'In Progress',
        text: vm.$t('In Progress'),
      },
      {
        value: 'Not Onboarded Yet',
        text: vm.$t('Not Onboarded Yet'),
      },
    ]);

    const setStatus = () => {};

    const confirmDelete = () => {
      return deactivateCompany(companyToDelete.value).then(() => {
        selectedCopmanies.value = [];
        deleteCompanyModal.value = false;
        vm.$store.commit('showMessage', {
          text: 'adminSuccessDeactivateCompany',
          color: 'success',
          timeout: '2000',
        });

        fetchAll();
      });
    };
    const onDelete = (item) => {
      deleteCompanyModal.value = true;
      if (item.id) {
        companyToDelete.value = item.id;
        companyNameToDelete.value = item.companyName;
      }
    };

    const onCreatedCompany = (data) => {
      newCompanyId.value = data.id;
      createCompanyModal.value = false;
      continueCreatingModal.value = true;
    };

    const onSkipOnboarding = () => {
      newCompanyId.value = null;
      continueCreatingModal.value = false;
    };

    return {
      loadedCompanies,
      totalCompanies,
      loadingCompanies,
      searchQuery,
      headers,
      statusOptions,
      statusFilter,
      companiesOptions,
      rerenderKey,
      companyToDelete,
      companyNameToDelete,
      selectedCopmanies,
      deleteCompanyModal,
      onDelete,
      setLimit,
      handlePagination,
      handleRowClick,
      setStatus,
      confirmDelete,
      createCompanyModal,
      continueCreatingModal,
      newCompanyId,
      onCreatedCompany,
      onSkipOnboarding,

      companyData: {
        name: '',
        displayName: '',
        language: '',
        orgType: ORG_TYPE_NEW,
        useFormalLang: false,
        orgId: '',
      },
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiInformationOutline,
        mdiCheckCircle,
        mdiUploadOutline,
      },
    };
  },
};
</script>
